<template>
  <v-dialog
    v-model="dialog"
    fullscreen hide-overlay
    transition="dialog-bottom-transition"
    >
    <template v-slot:activator="{on}">
      <v-btn text v-on="on">
        <v-icon>add</v-icon> {{ translate('Create new channel') }}
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{translate('Create new channel')}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            :label="translate('Channel Id')"
            v-model="channelId"
            :rules="validationRules.identifier()"
          />
          <v-autocomplete
            v-model="collectionId"
            :label="translate('Collection')"
            :items="schemas"
            item-text="label"
            item-value="collectionId"
            outline
            :rules="validationRules.listValue()"
            />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="create">{{translate('Create')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
  props: [],
  data: () => ({
    dialog: false,
    channelId: 'awesome-channel',
    collectionId: null
  }),
  computed: {
    ...mapGetters(['translate', 'api', 'validationRules', 'collectionsConfig'])
  },
  asyncComputed: {
    schemas: {
      async get () {
        // Get all collection schemas (including attributes), expanded with labels and sorting
        let schemas = await this.api.collections.getCollectionSchemas()
        return this.collectionsConfig.mapCollectionSchemas(schemas)
      },
      default: []
    }
  },
  methods: {
    async create () {
      if (!this.$refs.form.validate()) {
        return
      }
      let update = {
        channelId: this.channelId,
        collectionId: this.collectionId,
        attributes: {}
      }
      let channel = await this.api.channels.createChannel({update})
      this.$emit('channel-created', channel)
      this.dialog = false
    }
  }
}
</script>
