<template>
  <layout-page>
    <template v-slot:toolbar-title>
      <v-toolbar-title>{{translate('Channels')}}</v-toolbar-title>
    </template>
    <template v-slot:toolbar-items>
      <v-toolbar-items>
        <create-channel-dialog v-on:channel-created="mutations = mutations + 1"/>
        <v-btn text @click="save()"><v-icon>save</v-icon> {{translate('Save')}}</v-btn>
      </v-toolbar-items>
    </template>
    <v-card>
      <v-card-text>
        <v-data-table
          v-sortable-table="'.drag-handle'"
          @drag-sorted="dragSorted({...$event, items: clientSettings.channels})"
          :items="clientSettings.channels"
          item-key="channelId"
          :hide-default-header="true"
          :hide-default-footer="true"
        >
        <template v-slot:body="{items}">
          <tbody>
            <tr v-for="channel in items" :key="channel.channelId">
              <td class="drag-handle">
                <v-icon>swap_vert</v-icon>
                {{channel.channelId}}
              </td>
              <td>
                <v-text-field :label="translate('Display name', 'displayname')" :placeholder="channel.label" v-model="channel.label" />
              </td>
              <td>
                <v-checkbox
                  v-model="channel.list.show"
                  :label="translate('Show in listings')" />
              </td>
              <td>
                <v-checkbox
                  v-model="channel.form.show"
                  :label="translate('Show on cards')" />
              </td>
              <td style="width: 1px">
                <channel-dialog :channelId="channel.channelId" :label="channel.label">
                  <template v-slot:activator="{on}">
                    <v-btn small icon v-on="on">
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </template>
                </channel-dialog>
              </td>
              <td>
                <confirm-button>
                  <template v-slot:button="confirm">
                    <v-btn text small icon color="warning" @click="confirm">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:confirm="cancel">
                    <v-item-group multiple>
                      <v-btn text small color="error" @click="deleteChannel(channel)">
                        <v-icon>delete</v-icon> {{translate('Delete this channel?', 'remove.channel')}}
                      </v-btn>
                      <v-btn text small icon @click="cancel">
                        <v-icon>cancel</v-icon>
                      </v-btn>
                    </v-item-group>
                  </template>
                </confirm-button>
              </td>
            </tr>
          </tbody>
        </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </layout-page>
</template>
<script>
import LayoutPage from '@/components/layout/layout-page.vue'
import ConfirmButton from '@/components/confirm-button'
import SortableTable from '@/directives/sortable-table'
import CreateChannelDialog from './create-channel-dialog'
import ChannelDialog from './channel-dialog'
import {mapGetters, mapActions} from 'vuex'

export default {
  data: () => ({
    mutations: 1,
  }),
  components: {
    LayoutPage,
    CreateChannelDialog,
    ChannelDialog,
    ConfirmButton
  },
  directives: {
    SortableTable
  },
  computed: {
    ...mapGetters(['api', 'translate', 'channelsConfig']),
    headers () {
      return [{
        text: 'Channel Id',
        value: 'channelId',
        sortable: false
      }, {
        text: 'Label',
        value: 'label',
        sortable: false
      }]
    }
  },
  asyncComputed: {
    clientSettings: {
      async get () {
        let {items: channels} = this.mutations && await this.api.channels.getChannels({cached: false})
        return this.channelsConfig.mapClientSettings(channels)
      },
      default: []
    }
  },
  methods: {
    ...mapActions(['updateClientConfig']),
    dragSorted ({newIndex, oldIndex, items} = event) {
      items.splice(newIndex, 0, items.splice(oldIndex, 1)[0])
    },
    save () {
      let cs = this.channelsConfig.normalizeClientSettings(this.clientSettings)
      return this.updateClientConfig (cs)
    },
    async deleteChannel (channel) {
      await this.api.channels.deleteChannel(channel)
      // splice out of settings
      let channels = this.clientSettings.channels
      let index = channels.indexOf(channel)
      channels.splice(index, 1)
    }
  }
}

</script>
