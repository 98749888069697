<template>
  <v-dialog
    v-model="dialog"
    fullscreen hide-overlay
    transition="dialog-bottom-transition"
    >
    <template v-slot:activator="{on}">
      <v-btn small icon color="primary" v-on="on">
        <v-icon>edit</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{label}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
          <v-btn text primary @click="save">{{translate('Save')}}</v-btn>
          <v-btn text icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-layout row>
          <v-flex xs12 sm6>
            <span v-if="chartData.length === 0">
              {{translate('Enable individual attributes for completeness evaluation.')}}
            </span>
            <pie-chart :data="chartData" suffix="%"></pie-chart>
          </v-flex>
          <v-flex xs12 sm6>
            <v-data-table
              :hide-default-actions="true"
              :hide-default-headers="true"
              :loading="channel.loading"
              :items="channel.attributes"
              :headers="[{sortable: false},{sortable: false}]"
              >
              <template v-slot:body="{items}">
                <tr v-for="item in items" :key="item.name">
                  <td>
                    {{item.label}}
                  </td>
                  <td>
                    <v-slider
                      :max="channel.maxWeight"
                      v-model="item.weight"
                      :label="translate('Weight/relevance')"
                      />
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapGetters} from 'vuex'

function adjustMaxWeight (maxWeight) {
  let recommended = 100
  while (!(recommended >= maxWeight)) {
    recommended = recommended * 10
  }
  return recommended
}
export default {
  props: ['channelId', 'label'],
  data: () => ({
    dialog: false,
    pagination: {
      sortBy: null,
      rowsPerPage: -1
    }
  }),
  computed: {
    ...mapGetters(['api', 'translate', 'channelConfig', 'collectionsConfig']),
    chartData () {
      return this.channel.attributes
        .filter(({weight}) => weight > 0)
        .map(({label, weight}) => ([label, weight]))
    }
  },
  asyncComputed: {
    channel: {
      async get () {
        if (!this.dialog) {
          return {channel: {}, attributes: []}
        }

        let channel = await this.api.channels.getChannel({channelId: this.channelId})
        let collectionSchema = this.collectionsConfig.mapCollectionSchemas([channel.collectionSchema])[0]

        let maxWeight = adjustMaxWeight(Object.values(channel.attributes).reduce((max, w) => Math.max(max, w), 0))

        return {
          loading: false,
          channelId: channel.channelId,
          collectionId: channel.collectionId,
          channel,
          maxWeight,
          attributes: collectionSchema.attributes.map(({label, name}) => ({
            name,
            label,
            weight: channel.attributes[name] > 0 ? channel.attributes[name] : 0
          }))
        }
      },
      default: {
        loading: true,
        channel: {},
        attributes: []
      }
    }
  },
  methods: {
    async save () {
      let update = {
        channelId: this.channel.channelId,
        collectionId: this.channel.collectionId,
        attributes: this.channel.attributes
          .filter(({weight}) => weight > 0)
          .reduce((memo, {name, weight}) => {
            memo[name] = weight
            return memo
          }, {})
      }
      let channel = await this.api.channels.updateChannel({update})
      this.$emit('channel-updated', channel)
      this.dialog = false
    }
  }
}
</script>
